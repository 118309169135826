import { DrugRecommendation } from "../../types";

interface DrugRecommendationProps {
    recommendations: DrugRecommendation[];
}

export default function DrugRecommendations({ recommendations }: DrugRecommendationProps) {
    const filteredRecommendations = recommendations.filter((x) => x.text !== "No recommendation");
    return (
        <div className="recommendations">
            <ul>
                {filteredRecommendations.map((e, i) => {
                    return (
                        <li key={i} className="recommendation no-break">
                            <div className="space-between">
                                <h3>{e.drugname}</h3>
                                <span className="population">
                                    <img src="/person.svg" alt="population" className="icon" />
                                    <span>{e.population}</span>
                                </span>
                            </div>
                            <p>{e.text}</p>
                            <table className="implications">
                                <thead>
                                    <tr>
                                        <th>GENE</th>
                                        <th>RESULT</th>
                                        <th>IMPLICATION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(e.implications).map(([k, v], i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <a href="/" className="link">
                                                        {k}
                                                    </a>
                                                </td>
                                                <td>{e.lookupkey[k]}</td>
                                                <td>{v}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {e.comments !== null && (
                                <p className="info-box">
                                    <img
                                        src="/info-circle.svg"
                                        alt="info icon"
                                        className="icon"
                                        style={{ margin: "0 8px -3px 0" }}
                                    />
                                    {e.comments}
                                </p>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
