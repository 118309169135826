import { Drugs } from "../../types";

type Props = {
    drugs: Drugs;
};

export default function DrugTable({ drugs }: Props) {
    return (
        <div>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th style={{ width: "30%" }}></th>
                        <th style={{ width: "20%" }}>HIGH RISK</th>
                        <th style={{ width: "20%" }}>MODERATE RISK</th>
                        <th style={{ width: "20%" }}>LIMITED RISK</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(drugs).map(([category, drugs], i) => {
                        const highRisk = drugs.filter((x) => x.level === 3);
                        const moderateRisk = drugs.filter((x) => x.level === 2);
                        const limitedRisk = drugs.filter((x) => x.level === 1);
                        return (
                            <tr key={i}>
                                <td>{category}</td>
                                <td>
                                    {highRisk.map((e, i) => (
                                        <div key={i}>{e.name}</div>
                                    ))}
                                </td>
                                <td>
                                    {moderateRisk.map((e, i) => (
                                        <div key={i}>{e.name}</div>
                                    ))}
                                </td>
                                <td>
                                    {limitedRisk.map((e, i) => (
                                        <div key={i}>{e.name}</div>
                                    ))}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
