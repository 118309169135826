import { GeneResult } from "../../types";
import Result from "./Result";

// export const chartEvents: ReactGoogleChartEvent[] = [
//     {
//         eventName: "select",
//         callback: ({ chartWrapper }) => {
//             const chart = chartWrapper.getChart();
//             const selection = chart.getSelection();
//             console.log(selection);
// if (selection.length === 1) {
//     const [selectedItem] = selection;
//     const dataTable = chartWrapper.getDataTable();
//     const { row, column } = selectedItem;

//     console.log("You selected:", {
//         row,
//         column,
//         value: dataTable?.getValue(row, column),
//     });
// }
//         },
//     },
// ];

interface ResultsProps {
    results: { [key: string]: GeneResult[] };
}

export default function Results({ results }: ResultsProps) {
    return (
        <div className="results">
            <ul>
                {Object.entries(results).map(([gene, results], i) => {
                    return <Result gene={gene} results={results} />;
                })}
            </ul>
        </div>
    );
}
