import Select from "react-select";
import { useState, useEffect } from "react";
import { GENE_RESULTS } from "../../data";
import { Option } from "../../types";
import { ActionMeta } from "react-select";

interface ResultProps {
    results: string[];
    setResults: (newResults: string[]) => void;
}

export default function Results({ results, setResults }: ResultProps) {
    const [options, setOptions] = useState<Option[]>([]);

    // set options based on results
    useEffect(() => {
        let gene_results = GENE_RESULTS;
        if (results.length > 0) {
            // filter by gene names
            gene_results = GENE_RESULTS.filter(
                (x) => !results.some((y) => x.split(" ")[0] === y.split(" ")[0])
            );
        }
        const options = gene_results.map((x) => {
            return { value: x, label: x };
        });
        setOptions(options);
    }, [results]);

    const handleChange = (
        index: number,
        option: Option | null,
        actionMeta: ActionMeta<Option | null> | null
    ) => {
        // handle a selection
        if (option) {
            const newResults = results.slice();
            newResults[index] = option.value;
            setResults(newResults);
        }
        // handle clearing
        if (actionMeta?.action === "clear") {
            const newResults = results.slice();
            newResults.splice(index, 1);
            setResults(newResults);
        }
    };

    return (
        <div className="results">
            {results.map((e, i) => {
                return (
                    <div className="result" key={i}>
                        <Select
                            value={{ value: e, label: e }}
                            options={options}
                            onChange={(option, actionMeta) => handleChange(i, option, actionMeta)}
                            isClearable={true}
                        />
                    </div>
                );
            })}
            <div className="result">
                <Select
                    value={null}
                    options={options}
                    onChange={(option, actionMeta) => {
                        handleChange(results.length, option, actionMeta);
                    }}
                />
            </div>
        </div>
    );
}
