import { useState } from "react";
import Header from "./components/Header";
import Report from "./components/Report/Report";
import Form from "./components/Form/Form";
import Nav from "./components/Nav";
import { parseResults, parseVariants } from "./utils";
import { EMPTY_USER_DATA, EMPTY_REPORT_DATA } from "./data";

function App() {
    const [userData, setUserData] = useState(EMPTY_USER_DATA);
    const [reportData, setReportData] = useState(EMPTY_REPORT_DATA);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(parseResults(userData.results));
        // fetch("https://api.genebrains.com/report", {
        fetch("http://127.0.0.1:5000/report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    ethnicity: userData.ethnicity,
                    results: parseResults(userData.results),
                    variants: parseVariants(userData.variants),
                },
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setReportData(data);
                setIsLoading(false);
            });
    };

    const handleReset = () => {
        setUserData(EMPTY_USER_DATA);
        setReportData(EMPTY_REPORT_DATA);
    };

    return (
        <div className="app">
            <Header />
            <div className="content">
                <Nav />
                <Report userData={userData} reportData={reportData} isLoading={isLoading} />
                <Form
                    userData={userData}
                    setUserData={setUserData}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />
            </div>
        </div>
    );
}

export default App;
