import { Drugs, Option, ReportData, UserData } from "./types";

export const EMPTY_USER_DATA: UserData = {
    ethnicity: null,
    results: [],
    variants: null,
};

export const EMPTY_REPORT_DATA: ReportData = {
    results: {},
    recommendations: [],
};

export const SEQUENCE_PLACEHOLDER = `rs123123123 A/A
rs456456456 A/S
rs789789789 A/delGAG
`;

// Results should only have 1 hyphen. Is used in parsing on form submit.
export const GENE_RESULTS = [
    "ABCG2 - Poor Function",
    "ABCG2 - Decreased Function",
    "ABCG2 - Normal Function",
    "CACNA1S - Uncertain Susceptibility",
    "CACNA1S - Malignant Hyperthermia Susceptibility",
    "CFTR - ivacaftor non-responsive in CF patients",
    "CFTR - ivacaftor responsive in CF patients",
    "CYP2B6 - Poor Metabolizer",
    "CYP2B6 - Intermediate Metabolizer",
    "CYP2B6 - Indeterminate",
    "CYP2B6 - Normal Metabolizer",
    "CYP2B6 - Rapid Metabolizer",
    "CYP2B6 - Ultrarapid Metabolizer",
    "CYP2C19 - Rapid Metabolizer",
    "CYP2C19 - Ultrarapid Metabolizer",
    "CYP2C19 - Poor Metabolizer",
    "CYP2C19 - Likely Intermediate Metabolizer",
    "CYP2C19 - Indeterminate",
    "CYP2C19 - Intermediate Metabolizer",
    "CYP2C19 - Normal Metabolizer",
    "CYP2C19 - Likely Poor Metabolizer",
    "CYP2C9 - Intermediate Metabolizer",
    "CYP2C9 - Poor Metabolizer",
    "CYP2C9 - Indeterminate",
    "CYP2C9 - Normal Metabolizer",
    "CYP2D6 - Indeterminate",
    "CYP2D6 - Poor Metabolizer",
    "CYP2D6 - Ultrarapid Metabolizer",
    "CYP2D6 - Normal Metabolizer",
    "CYP2D6 - Intermediate Metabolizer",
    "CYP3A5 - Poor Metabolizer",
    "CYP3A5 - Intermediate Metabolizer",
    "CYP3A5 - Possible Intermediate Metabolizer",
    "CYP3A5 - Indeterminate",
    "CYP3A5 - Normal Metabolizer",
    "DPYD - Normal Metabolizer",
    "DPYD - Poor Metabolizer",
    "DPYD - Intermediate Metabolizer",
    "G6PD - Variable",
    "G6PD - Indeterminate",
    "G6PD - Normal",
    "G6PD - Deficient",
    "G6PD - Deficient with CNSHA",
    "HLA-A - *31:01 negative",
    "HLA-A - *31:01 positive",
    "HLA-B - *57:01 negative",
    "HLA-B - *57:01 positive",
    "HLA-B - *58:01 positive",
    "HLA-B - *15:02 positive",
    "HLA-B - *15:02 negative",
    "HLA-B - *58:01 negative",
    "MT-RNR1 - uncertain risk of aminoglycoside-induced hearing loss",
    "MT-RNR1 - normal risk of aminoglycoside-induced hearing loss",
    "MT-RNR1 - increased risk of aminoglycoside-induced hearing loss",
    "NUDT15 - Indeterminate",
    "NUDT15 - Intermediate Metabolizer",
    "NUDT15 - Normal Metabolizer",
    "NUDT15 - Possible Intermediate Metabolizer",
    "NUDT15 - Poor Metabolizer",
    "RYR1 - Uncertain Susceptibility",
    "RYR1 - Malignant Hyperthermia Susceptibility",
    "SLCO1B1 - Decreased Function",
    "SLCO1B1 - Poor Function",
    "SLCO1B1 - Indeterminate",
    "SLCO1B1 - Possible Decreased Function",
    "SLCO1B1 - Normal Function",
    "SLCO1B1 - Increased Function",
    "TPMT - Poor Metabolizer",
    "TPMT - Intermediate Metabolizer",
    "TPMT - Normal Metabolizer",
    "TPMT - Possible Intermediate Metabolizer",
    "TPMT - Indeterminate",
    "UGT1A1 - Indeterminate",
    "UGT1A1 - Normal Metabolizer",
    "UGT1A1 - Poor Metabolizer",
    "UGT1A1 - Intermediate Metabolizer",
];

export const ETHNICITY_OPTIONS: Option[] = [
    {
        value: "African American/Afro-Caribbean",
        label: "African American",
    },
    {
        value: "African American/Afro-Caribbean",
        label: "Afro-Caribbean",
    },
    { value: "Central/South Asian", label: "Central/South Asian" },
    { value: "East Asian", label: "East Asian" },
    { value: "European", label: "European" },
    { value: "Latino", label: "Latino" },
    { value: "American", label: "Native American" },
    { value: "Near Eastern", label: "Near Eastern" },
    { value: "Oceanian", label: "Oceanian" },
    { value: "Sub-Saharan African", label: "Sub-Saharan African" },
];

export const TEST_DRUGS: Drugs = {
    "anti-depressants": [
        {
            name: "perphenazine",
            level: 1,
        },
        {
            name: "olanzapine",
            level: 2,
        },
        {
            name: "wiejwiejf",
            level: 3,
        },
    ],
    cardiovascular: [
        {
            name: "perphenazine",
            level: 2,
        },
        {
            name: "olanzapine",
            level: 2,
        },
        {
            name: "oiwefjwe",
            level: 3,
        },
    ],
};
