import React, { useState } from "react";

interface TabProps {
    children: React.ReactElement[];
}

export default function Tabs({ children }: TabProps) {
    const [tab, setTab] = useState(0);
    return (
        <>
            <ol className="tabs">
                {children.map((e, i) => {
                    return (
                        <button
                            key={i}
                            className={i === tab ? "tab-title active" : "tab-title"}
                            onClick={() => setTab(i)}
                        >
                            {e.props.title}
                        </button>
                    );
                })}
            </ol>
            {children[tab]}
        </>
    );
}
