export function parseResults(results: string[]) {
    return results.map((x) => {
        let gene = x.substring(0, x.indexOf("-") - 1).trim();
        let result = x.substring(x.indexOf("-") + 1, x.length).trim();
        return { gene, result };
    });
}

export function parseVariants(variants: string | null) {
    if (!variants || variants.length < 1) return [];
    let lines = variants.split("\n");
    return lines.map((x) => {
        let split = x.split(" ");
        return { location: split[0].trim(), value: split[1].trim() };
    });
}

// export function sortResults(results: GeneResult[]) {
//     results.sort((a, b) => a.gene.localeCompare(b.gene));
//     results.sort((a, b) => b.probability - a.probability);
//     return results;
// }

// export function sortDrugRecommendations(recommendations: DrugRecommendation[]) {
//     recommendations.sort((a, b) => a.drugname.localeCompare(b.drugname));
//     return recommendations;
// }

export const getImgSrc = (ehrpriority: string) => {
    if (ehrpriority.includes("Abnormal")) {
        return "/warning-2.svg";
    }
    if (ehrpriority === "none") {
        return "/warning-2.svg";
    }
    return "tick-circle.svg";
};

export const getConfidence = (probability: number) => {
    if (probability > 0.75) return "High";
    return "Low";
};

export const getRisk = (ehrpriority: string | null) => {
    if (ehrpriority === null || ehrpriority === "none") {
        return "Unknown";
    }
    if (ehrpriority.includes("Abnormal")) {
        return "High";
    }
    return "Normal";
};
