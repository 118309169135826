import React from "react";

type Props = {
    title: string;
    children: React.ReactElement | React.ReactElement[];
};

export default function Tab(props: Props) {
    return <div>{props.children}</div>;
}
