import Select, { ActionMeta } from "react-select";
import Results from "./Results";
import Variants from "./Variants";
import { ETHNICITY_OPTIONS } from "../../data";
import { Option, UserData } from "../../types";

interface FormProps {
    userData: UserData;
    setUserData: React.Dispatch<React.SetStateAction<UserData>>;
    handleSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
    handleReset: () => void;
}
export default function Form(props: FormProps) {
    const handleEthnicityChange = (option: Option | null, actionMeta: ActionMeta<Option>) => {
        if (option) {
            props.setUserData({ ...props.userData, ethnicity: option.value });
        }
        if (actionMeta.action === "clear") {
            props.setUserData({ ...props.userData, ethnicity: null });
        }
    };

    const setResults = (newResults: string[]) => {
        props.setUserData({ ...props.userData, results: newResults });
    };

    const setVariants = (newVariants: string) => {
        props.setUserData({ ...props.userData, variants: newVariants });
    };

    return (
        <form className="form no-print" onSubmit={(e) => props.handleSubmit(e)}>
            <h3>Ethnicity</h3>
            <Select
                options={ETHNICITY_OPTIONS}
                onChange={(option, actionMeta) => handleEthnicityChange(option, actionMeta)}
                isClearable={true}
            />
            <h3>Results</h3>
            <Results results={props.userData.results} setResults={setResults} />
            <Variants setVariants={setVariants} />
            <div className="button-container">
                <button type="submit" className="primary">
                    Go
                </button>
                <button type="button" onClick={props.handleReset} className="secondary">
                    Reset
                </button>
            </div>
        </form>
    );
}
