import QRCode from "react-qr-code";

export default function QRCard() {
    return (
        <div className="qr-card">
            <div>
                <h4>genebrains</h4>
                <p>Quick Access PGx Results</p>
            </div>
            <QRCode value="hey" style={{ maxWidth: "100px", maxHeight: "100px" }} />
        </div>
    );
}
