import React from "react";
import { SEQUENCE_PLACEHOLDER } from "../../data";

interface VariantProps {
    setVariants: (variants: string) => void;
}

export default function Variants({ setVariants }: VariantProps) {
    return (
        <div className="variants">
            <h3>Variants</h3>
            <textarea
                name="variants"
                spellCheck="false"
                placeholder={SEQUENCE_PLACEHOLDER}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setVariants(e.target.value)
                }
            ></textarea>
        </div>
    );
}
