export default function Header() {
    return (
        <header className="no-print">
            <span className="logo">genebrains</span>
            <nav>
                <a href="/">About</a>
                <a href="/">Docs</a>
            </nav>
        </header>
    );
}
