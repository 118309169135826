import { ReportData, UserData } from "../../types";
import Recommendations from "./Recommendations";
import Results from "./Results";
import Drugs from "./DrugTable";
import Tabs from "./Tabs";
import Tab from "./Tab";
import { TEST_DRUGS } from "../../data";
import QRCard from "./QRCard";

interface ReportProps {
    userData: UserData;
    reportData: ReportData;
    isLoading: boolean;
}

export default function Report(props: ReportProps) {
    const numResults = Object.keys(props.reportData.results).length;
    if (props.isLoading) {
        return <div className="report-loading">loading...</div>;
    }
    if (numResults < 1) {
        return (
            <div className="report-placeholder">
                <p>
                    Pharmacogenetic insights from the <br></br>
                    <a href="/" target="_blank" className="link">
                        FDA
                    </a>{" "}
                    and{" "}
                    <a href="/" target="_blank" className="link">
                        CPIC
                    </a>{" "}
                    for any patient.
                </p>
            </div>
        );
    }

    return (
        <div className="report">
            <div className="space-between" style={{ marginTop: "8px" }}>
                <div style={{ marginRight: "24px" }}>
                    <h2>{`${numResults}-Gene Pharmacogenetic Report`}</h2>
                    <p>
                        This report is provides an overview of key pharmacogenetic factors and is
                        intended to act as an informational resource for medical decision makers.
                    </p>
                </div>
                <div style={{ marginTop: "24px" }}>
                    <QRCard />
                </div>
            </div>
            <Tabs>
                <Tab title="Results">
                    <Results results={props.reportData.results} />
                </Tab>
                <Tab title="Recommendations">
                    <Recommendations recommendations={props.reportData.recommendations} />
                </Tab>
                <Tab title="Drugs">
                    <Drugs drugs={TEST_DRUGS} />
                </Tab>
            </Tabs>
        </div>
    );
}
