import { GeneResult } from "../../types";
import { getConfidence, getRisk } from "../../utils";
import Tag from "./Tag";

interface ResultProps {
    gene: string;
    results: GeneResult[];
}

export default function Result({ gene, results }: ResultProps) {
    const sortedResults = results.sort((a, b) => b.probability - a.probability);
    const topResult = sortedResults[0];
    return (
        <li className="report-result no-break">
            <div>
                <div className="space-between">
                    <h3>
                        {gene} - <i>{topResult.value}</i>
                    </h3>
                </div>
            </div>
            <div>
                <p style={{ marginTop: "0" }}>{topResult.consultationtext}</p>
                <div className="tags">
                    <Tag value={`${getRisk(topResult.ehrpriority)} Risk`} />
                    <Tag value={`${getConfidence(topResult.probability)} Confidence`} />
                </div>
            </div>
        </li>
    );
}

{
    /* <div className="priority">
    {topResult.ehrpriority && (
        <img
            src={getImgSrc(topResult.ehrpriority)}
            alt="warning status"
            className="icon"
        />
    )}
    <span>
        {topResult.ehrpriority === "none"
            ? "Indeterminate"
            : topResult.ehrpriority}
    </span>
</div> */
}
