// import { getImgSrc } from "../../utils";

interface TagProps {
    value: string;
}

export const COLOR_MAP: { [key: string]: string } = {
    "High Confidence": "green",
    "Low Confidence": "yellow",
    "High Risk": "red",
    "Moderate Risk": "yellow",
    "Normal Risk": "green",
    "Unknown Risk": "gray",
};

export default function Tag({ value }: TagProps) {
    return (
        <div className={`tag ${COLOR_MAP[value]}`}>
            {/* <img
                src={getImgSrc(value)}
                alt={`${value} ${metric}`}
                className="icon"
                style={{ margin: "0 8px -3px 0" }}
            /> */}
            {value}
        </div>
    );
}
